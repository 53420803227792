import { Layout, Menu } from "antd";
import { Link, Outlet } from "react-router-dom";

const { Header, Content, Footer } = Layout;

function Loggedtemplate() {
  return (
    <Layout
      style={{
        minHeight: "100%",
      }}
    >
      <Header style={{ display: "flex", alignItems: "center" }}>
        <div className="demo-logo" />
        <Menu
          theme="dark"
          mode="horizontal"
          items={[
            {
              key: "dashboard",
              label: <Link to={"/dashboard"}>Dashboard</Link>,
            },
            {
              key: "monitors",
              label: <Link to={"/monitors"}>Monitoramentos</Link>,
            },
            {
              key: "contacts",
              label: <Link to={"/contacts"}>Contatos</Link>,
            },
          ]}
          style={{ flex: 1, minWidth: 0 }}
        />
      </Header>
      <Content className="body-content">
        <Outlet />
      </Content>
      <Footer style={{ textAlign: "center" }}>
        PulseWard ©{new Date().getFullYear()}
      </Footer>
    </Layout>
  );
}

export default Loggedtemplate;
