import {
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  ExclamationCircleFilled,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Card,
  Dropdown,
  Flex,
  MenuProps,
  Modal,
  Skeleton,
  Space,
  Table,
  TableProps,
  Typography,
} from "antd";
import { useCallback } from "react";
import { useQuery } from "react-query";
import MediaQuery from "react-responsive";
import { Link, useNavigate } from "react-router-dom";
import { Api } from "../../services/Api";

const { confirm } = Modal;

function dateToAge(date: string) {
  const now = new Date();
  const parsed = new Date(date);
  const differenceInMinutes = Math.floor(
    (now.getTime() - parsed.getTime()) / 60000
  );
  if (differenceInMinutes < 1) {
    return "agora mesmo";
  }
  if (differenceInMinutes < 60) {
    return `${differenceInMinutes} ${
      differenceInMinutes > 1 ? "minutos" : "minuto"
    }`;
  }
  const differenceInHours = Math.floor(differenceInMinutes / 60);
  if (differenceInHours < 60) {
    return `${differenceInHours} ${differenceInHours > 1 ? "horas" : "hora"}`;
  }
  const differenceInDays = Math.floor(differenceInHours / 24);
  if (differenceInDays < 60) {
    return `${differenceInDays} ${differenceInDays > 1 ? "dias" : "dia"}`;
  }
  const differenceInMonths = Math.floor(differenceInDays / 30);
  if (differenceInMonths < 60) {
    return `${differenceInMonths} ${differenceInMonths > 1 ? "meses" : "mês"}`;
  }
  const differenceInYears = Math.floor(differenceInMonths / 12);
  if (differenceInYears < 60) {
    return `${differenceInYears} ${differenceInYears > 1 ? "anos" : "ano"}`;
  }
}

function ListContacts() {
  const navigate = useNavigate();

  const { data, isLoading, refetch } = useQuery("contacts", async () => {
    const response = await Api.get("/contacts");
    return response.data.map((item: any) => ({
      ...item,
      key: item.id,
      age: dateToAge(item.createdAt),
    }));
  });

  const deleteContact = useCallback(
    (id: string) => {
      Api.delete(`/contacts/${id}`).finally(() => refetch());
    },
    [refetch]
  );

  const showDeleteConfirm = (id: string) => {
    confirm({
      title: "Deseja excluir o contato?",
      icon: <ExclamationCircleFilled />,
      content: "Após excluído, não será mais possível recuperá-lo.",
      okText: "Excluir",
      okType: "danger",
      cancelText: "Manter",
      onOk() {
        deleteContact(id);
      },
    });
  };

  const columns: TableProps<any>["columns"] = [
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
    },
    // {
    //   title: "Tipo",
    //   dataIndex: "type",
    //   key: "type",
    //   render: (text) => (
    //     <Typography.Text type="secondary">{text}</Typography.Text>
    //   ),
    // },
    {
      title: "E-mail",
      dataIndex: "email",
      key: "email",
      render: (text) => (
        <Typography.Text type="secondary">{text}</Typography.Text>
      ),
    },
    {
      title: "Criado há",
      dataIndex: "age",
      key: "age",
      render: (text) => (
        <Typography.Text type="secondary">{text}</Typography.Text>
      ),
    },
    {
      title: "",
      key: "action",
      render: (text, row) => {
        const items: MenuProps["items"] = [
          {
            key: "edit",
            icon: <EditOutlined />,
            label: <Link to={`${row.id}/edit`}>Editar</Link>,
          },
          {
            key: "remove",
            danger: true,
            icon: <DeleteOutlined />,
            label: "Excluir",
            onClick: () => showDeleteConfirm(row.id),
          },
        ];

        if (row.default) {
          return null;
        }

        return (
          <Dropdown menu={{ items }}>
            <a href="/dashboard" onClick={(e) => e.preventDefault()}>
              <Space>
                Ações
                <DownOutlined />
              </Space>
            </a>
          </Dropdown>
        );
      },
    },
  ];

  return (
    <>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Contatos</Breadcrumb.Item>
      </Breadcrumb>
      <div
        style={{
          background: "#ffffff",
          minHeight: 280,
          padding: 24,
          borderRadius: 8,
        }}
      >
        <Flex justify="end" align="center" style={{ marginBottom: "1rem" }}>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              navigate("new");
            }}
          >
            Criar um novo contato
          </Button>
        </Flex>

        <MediaQuery maxWidth={600}>
          <Skeleton loading={isLoading}>
            {data?.map((m: any) => (
              <Card
                style={{ marginBottom: "1rem" }}
                onClick={() => {
                  navigate(`/contacts/${m.id}/edit`);
                }}
              >
                <Flex justify="space-between" align="center">
                  <div>
                    <Typography.Text>{m.name}</Typography.Text>
                    <br />
                    <Typography.Text type="secondary">
                      {m.email}
                    </Typography.Text>
                  </div>
                </Flex>
              </Card>
            ))}
          </Skeleton>
        </MediaQuery>

        <MediaQuery minWidth={601}>
          <Table
            columns={columns}
            dataSource={data}
            loading={isLoading}
            pagination={false}
          />
        </MediaQuery>
      </div>
    </>
  );
}

export default ListContacts;
