import axios from "axios";

export function getErrorMessage(error: unknown) {
  if (axios.isAxiosError(error)) {
    return (
      error.response?.data?.error || error.response?.data?.message || "Erro"
    );
  }
  return "Erro";
}
