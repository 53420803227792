import { Breadcrumb, Col, Flex, Row, Typography } from "antd";
import { Link } from "react-router-dom";
import { ContactForm } from "../../components/ContactForm";

function AddContact() {
  return (
    <>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>
          <Link to="../">Contatos</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Adicionar novo contato</Breadcrumb.Item>
      </Breadcrumb>
      <div
        style={{
          background: "#ffffff",
          padding: 24,
          borderRadius: 8,
          paddingTop: "3rem",
          paddingBottom: "3rem",
        }}
      >
        <Row style={{ height: "100%" }}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Flex align="center" style={{ height: "100%" }}>
              <div>
                <Typography.Title style={{ margin: 0 }}>
                  Novo contato
                </Typography.Title>
                <Typography.Paragraph
                  style={{ marginBottom: 0 }}
                  type="secondary"
                >
                  Contatos poderão ser atribuídos a monitoramentos para serem
                  notificados sobre quedas, retornos e relatórios.
                </Typography.Paragraph>
              </div>
            </Flex>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <ContactForm />
          </Col>
        </Row>
      </div>
    </>
  );
}

export default AddContact;
