import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import AddContact from "./pages/AddContact";
import AddMonitor from "./pages/AddMonitor";
import ListContacts from "./pages/ListContacts";
import ListMonitors from "./pages/ListMonitors";
import Loggedtemplate from "./pages/LoggedTemplate";
import Register from "./pages/Register";
import ShowMonitor from "./pages/ShowMonitor";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import EditMonitor from "./pages/EditMonitor";
import EditContact from "./pages/EditContact";
import RegisterForForms from "./pages/RegisterForForms";
import RegisterForFormsSuccess from "./pages/RegisterForFormsSuccess";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Routes>
          <Route path="/register" Component={() => <Register />} />
          <Route
            path="/register-for-forms"
            Component={() => <RegisterForForms />}
          />
          <Route
            path="/register-for-forms-success"
            Component={() => <RegisterForFormsSuccess />}
          />
          <Route path="/login" Component={() => <Login />} />
          <Route path="/dashboard" element={<Loggedtemplate />}>
            <Route path="" Component={() => <Dashboard />} />
          </Route>
          <Route path="/monitors" element={<Loggedtemplate />}>
            <Route path="" Component={() => <ListMonitors />} />
            <Route path="new" Component={() => <AddMonitor />} />
            <Route path=":id/edit" Component={() => <EditMonitor />} />
            <Route path=":id" Component={() => <ShowMonitor />} />
          </Route>
          <Route path="/contacts" element={<Loggedtemplate />}>
            <Route path="" Component={() => <ListContacts />} />
            <Route path=":id/edit" Component={() => <EditContact />} />
            <Route path="new" Component={() => <AddContact />} />
          </Route>
          <Route path="*" element={<Navigate to="/dashboard" />} />
        </Routes>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
