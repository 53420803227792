import { Breadcrumb, Col, Flex, List, Row, Typography } from "antd";
import { Link } from "react-router-dom";
import { MonitorForm } from "../../components/MonitorForm";
import { CheckOutlined } from "@ant-design/icons";

function AddMonitor() {
  return (
    <>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>
          <Link to="../">Monitoramentos</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Configurar novo monitoramento</Breadcrumb.Item>
      </Breadcrumb>
      <div
        style={{
          background: "#ffffff",
          minHeight: 280,
          padding: 24,
          borderRadius: 8,
          paddingTop: "3rem",
          paddingBottom: "3rem",
        }}
      >
        <Row>
          <Col xs={0} sm={0} md={0} lg={12} xl={12}>
            <Typography.Title style={{ margin: 0 }}>
              Novo monitoramento
            </Typography.Title>
            <Typography.Paragraph
              style={{ margin: 0, marginBottom: "1rem" }}
              type="secondary"
            >
              Monitore a disponibilidade de um link de internet, serviço,
              servidor ou website.
            </Typography.Paragraph>

            <List
              size="small"
              bordered
              style={{
                width: "fit-content",
              }}
              dataSource={[
                "Disponibilidade",
                "Histórico de latência",
                "Registro de incidentes (quedas)",
                "Relatórios de SLA",
              ]}
              renderItem={(item: any) => (
                <List.Item>
                  <Flex gap={"middle"}>
                    <CheckOutlined />
                    <Typography.Text>{item}</Typography.Text>
                  </Flex>
                </List.Item>
              )}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <MonitorForm />
          </Col>
        </Row>
      </div>
    </>
  );
}

export default AddMonitor;
