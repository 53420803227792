import {
  Breadcrumb,
  Card,
  Divider,
  Empty,
  Flex,
  Popover,
  Select,
  Skeleton,
  Space,
  Table,
  TableProps,
  Tag,
  Typography,
} from "antd";
import Chart from "react-apexcharts";
import { useQuery } from "react-query";
import MediaQuery, { useMediaQuery } from "react-responsive";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { Api } from "../../services/Api";
import { getColorForUptime } from "../../utils/getColorForUptime";

export function formatDuration(
  durationInMilliseconds: number | null | undefined
) {
  if (!durationInMilliseconds) return null;
  const differenceInSeconds = Math.floor(durationInMilliseconds / 1000);
  if (differenceInSeconds < 60) {
    return `${differenceInSeconds} ${
      differenceInSeconds > 1 ? "segundos" : "segundo"
    }`;
  }
  const differenceInMinutes = Math.floor(differenceInSeconds / 60);
  if (differenceInMinutes < 60) {
    return `${differenceInMinutes} ${
      differenceInMinutes > 1 ? "minutos" : "minuto"
    }`;
  }
  const differenceInHours = Math.floor(differenceInMinutes / 60);
  if (differenceInHours < 60) {
    return `${differenceInHours} ${differenceInHours > 1 ? "horas" : "hora"}`;
  }
  const differenceInDays = Math.floor(differenceInHours / 24);
  if (differenceInDays < 60) {
    return `${differenceInDays} ${differenceInDays > 1 ? "dias" : "dia"}`;
  }
  const differenceInMonths = Math.floor(differenceInDays / 30);
  if (differenceInMonths < 60) {
    return `${differenceInMonths} ${differenceInMonths > 1 ? "meses" : "mês"}`;
  }
  const differenceInYears = Math.floor(differenceInMonths / 12);
  if (differenceInYears < 60) {
    return `${differenceInYears} ${differenceInYears > 1 ? "anos" : "ano"}`;
  }
}

const { Paragraph } = Typography;

const columns: TableProps<any>["columns"] = [
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (text, row) => (
      <>
        {text === "problem" && <Tag color="volcano">Problema</Tag>}
        {text === "resolved" && <Tag color="green">Resolvido</Tag>}
      </>
    ),
  },
  {
    title: "Iniciado em",
    dataIndex: "startedAt",
    key: "startedAt",
  },
  {
    title: "Duração",
    dataIndex: "duration",
    key: "duration",
  },
  {
    title: "Causa",
    dataIndex: "cause",
    key: "cause",
    render: (text) => (
      <Typography.Text type="secondary">Destino não respondeu</Typography.Text>
    ),
  },
  {
    title: "",
    key: "action",
    render: (text, row) => {
      return null;
    },
  },
];

function ShowMonitor() {
  const isSmall = useMediaQuery({ query: "(max-width: 600px)" });

  const { id } = useParams<{ id: string }>();

  const { data: incidents, isLoading: isLoadingIncidents } = useQuery(
    `incidents:${id}`,
    async () => {
      const { data } = await Api.get(`/incidents`, {
        params: { monitorId: id },
      });
      return data.map((i: any) => {
        const duration =
          i.status === "resolved"
            ? new Date(i.resolvedAt).getTime() - new Date(i.createdAt).getTime()
            : null;

        return {
          ...i,
          startedAt: new Date(i.createdAt).toLocaleString(),
          duration: formatDuration(duration),
        };
      });
    }
  );

  const { data, isLoading } = useQuery(`monitor:${id}`, async () => {
    const { data } = await Api.get(`/monitors/${id}`);
    return data;
  });

  const { data: latencyHistogram, isLoading: isLoadingLatencyHistogram } =
    useQuery(`latency-histogram:${id}`, async () => {
      const { data } = await Api.get(`/latency-histogram/${id}`);
      return data.map((d: any) => ({
        ...d,
        latency: d.latency === null ? null : d.latency.toFixed(0),
      }));
    });

  return (
    <>
      <Flex align="center" justify="space-between">
        <Breadcrumb style={{ margin: "16px 0" }}>
          <Breadcrumb.Item>
            <Link to="../">Monitoramentos</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Monitor</Breadcrumb.Item>
        </Breadcrumb>
        <Select
          defaultValue="24h"
          style={{ width: 200 }}
          onChange={() => {}}
          options={[
            { value: "24h", label: "Últimas 24 horas" },
            // { value: "7d", label: "Últimos 7 dias" },
            // { value: "30d", label: "Últimos 30 dias" },
          ]}
        />
      </Flex>
      <div
        className="secondary-card"
        style={{
          background: "#ffffff",
          borderRadius: 8,
        }}
      >
        <Skeleton loading={isLoading}>
          <Typography.Title style={{ margin: 0 }}>
            {data?.name}
          </Typography.Title>
          <Paragraph type="secondary">{data?.address}</Paragraph>
        </Skeleton>

        <Skeleton loading={isLoading}>
          <Space>
            <Space size={2}>
              {!!data?.last24HoursUptime &&
                Array.isArray(data.last24HoursUptime) &&
                data.last24HoursUptime.map((item: any) => (
                  <Popover
                    content={
                      <>
                        {item.uptimePercentage !== null ? (
                          <p>{item.uptimePercentage?.toFixed(2)}%</p>
                        ) : (
                          <p>Sem dados</p>
                        )}
                      </>
                    }
                    title={`Disponibilidade às ${new Date(
                      item.startDate
                    ).toLocaleTimeString("pt-BR", {
                      hour: "2-digit",
                    })}h`}
                    key={item.startTime}
                  >
                    <div
                      key={item.startDate}
                      style={{
                        width: 8,
                        height: 24,
                        background: getColorForUptime(item.uptimePercentage),
                        borderRadius: 8,
                      }}
                    ></div>
                  </Popover>
                ))}
            </Space>

            {/* <Typography.Text>0% disponível nos últimos 30 dias</Typography.Text> */}
          </Space>
        </Skeleton>
      </div>

      <Typography.Title level={2}>Latência</Typography.Title>

      <div
        className="secondary-card"
        style={{
          background: "#ffffff",
          borderRadius: 8,
        }}
      >
        <Skeleton loading={isLoadingLatencyHistogram}>
          <Chart
            options={{
              chart: {
                id: "basic-bar",
                toolbar: {
                  show: false,
                },
              },
              dataLabels: {
                enabled: false,
              },
              xaxis: {
                categories: latencyHistogram?.map(
                  (item: any) => item.startDate
                ),
                type: "datetime",
              },
              yaxis: {
                show: !isSmall,
                labels: {
                  formatter: function (val: any) {
                    return `${val?.toFixed(0) || 0}ms`;
                  },
                },
              },
              stroke: {
                curve: "straight",
                width: 2,
                colors: ["#57bbff"],
              },
              fill: {
                colors: ["#57bbff"],
                gradient: {
                  opacityTo: 0.2,
                },
              },
            }}
            series={[
              {
                name: "Latência",
                data: latencyHistogram?.map((item: any) => item.latency),
              },
            ]}
            type="area"
            height={isSmall ? 192 : 256}
          />
        </Skeleton>
      </div>

      <Typography.Title level={2}>Incidentes</Typography.Title>

      <div
        className="secondary-card"
        style={{
          background: "#ffffff",
          borderRadius: 8,
        }}
      >
        {!isLoadingIncidents && !incidents?.length && (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="Nenhum incidente registrado."
          />
        )}

        <MediaQuery maxWidth={600}>
          <Skeleton loading={isLoadingIncidents}>
            {incidents?.map((m: any) => (
              <Card style={{ marginBottom: "1rem" }}>
                <Flex justify="space-between" align="start">
                  <div>
                    <Typography.Text>{m.startedAt}</Typography.Text>
                    {m.duration && (
                      <>
                        <br />
                        <Typography.Text type="secondary">
                          {m.duration}
                        </Typography.Text>
                      </>
                    )}
                  </div>
                  {m.resolvedAt ? (
                    <Tag color="green">Resolvido</Tag>
                  ) : (
                    <Tag color="red">Problema</Tag>
                  )}
                </Flex>

                <Divider
                  dashed
                  style={{ marginTop: "1rem", marginBottom: "1rem" }}
                />

                {m.duration && (
                  <Typography.Text type="secondary">
                    Destino não respondeu
                  </Typography.Text>
                )}
              </Card>
            ))}
          </Skeleton>
        </MediaQuery>

        <MediaQuery minWidth={601}>
          {(isLoadingIncidents || !!incidents?.length) && (
            <Table
              columns={columns}
              dataSource={incidents}
              loading={isLoadingIncidents}
              locale={{
                emptyText: "Nenhum incidente registrado",
              }}
            />
          )}
        </MediaQuery>
      </div>
    </>
  );
}

export default ShowMonitor;
