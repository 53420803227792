import { Button, Form, Input, Skeleton, Typography, message } from "antd";
import { FC, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router";
import { Api } from "../../services/Api";
import { getErrorMessage } from "../../utils/getErrorMessage";

interface Props {
  id?: string;
}

type FieldType = {
  name?: string;
  email?: string;
};

export const ContactForm: FC<Props> = ({ id }) => {
  const navigate = useNavigate();
  const [isLoadingForm, setIsLoadingForm] = useState<boolean>(true);
  const [initialValues, setInitialValues] = useState({});
  const [messageApi, contextHolder] = message.useMessage();

  const { mutate, isLoading } = useMutation({
    // @ts-ignore
    mutationFn: async (data: any) => {
      if (id) {
        await Api.patch(`/contacts/${id}`, {
          name: data.name,
          email: data.email,
        });
        navigate(`../`, {
          replace: true,
        });
      } else {
        await Api.post(`/contacts`, {
          name: data.name,
          email: data.email,
        });
        navigate(`../`, {
          replace: true,
        });
      }
    },
    onError: (error) => {
      messageApi.open({
        type: "error",
        content: getErrorMessage(error),
      });
    },
  });

  useEffect(() => {
    if (!id) return setIsLoadingForm(false);
    Api.get(`/contacts/${id}`).then(({ data }) => {
      setInitialValues(data);
      setIsLoadingForm(false);
    });
  }, [id]);

  if (isLoadingForm) return <Skeleton />;

  return (
    <Form
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      style={{ maxWidth: 600 }}
      initialValues={initialValues}
      onFinish={(values) => {
        mutate(values);
      }}
      onFinishFailed={() => {}}
      autoComplete="off"
    >
      {contextHolder}
      <Form.Item<FieldType>
        label="Nome"
        name="name"
        rules={[
          {
            required: true,
            message: "Informe um nome.",
          },
        ]}
      >
        <Input autoFocus />
      </Form.Item>

      <Form.Item<FieldType>
        label="E-mail"
        name="email"
        rules={[
          {
            required: true,
            message: "Informe um e-mail",
          },
        ]}
      >
        <Input type="email" />
      </Form.Item>

      <Form.Item
        wrapperCol={{
          offset: 8,
          span: 16,
        }}
      >
        <Typography.Text type="secondary">
          Se os alertas serão enviados a diversos e-mails do mesmo domínio,
          recomendamos utilizar uma lista de distribuição. Por exemplo,
          monitoramento@empresa.com.
        </Typography.Text>
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" htmlType="submit" loading={isLoading}>
          Salvar
        </Button>
      </Form.Item>
    </Form>
  );
};
