import axios from "axios";

export const Api = axios.create({
  baseURL: process.env.REACT_APP_API_URL || "http://localhost:8081/api/v1",
  withCredentials: true,
});

const unauthenticatedHandler = (error: any) => {
  if (error?.response?.status === 401) window.location.href = "/login";
  return Promise.reject(error);
};

Api.interceptors.response.use((request) => request, unauthenticatedHandler);
