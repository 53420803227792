import {
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  ExclamationCircleFilled,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Card,
  Dropdown,
  Flex,
  MenuProps,
  Modal,
  Skeleton,
  Space,
  Table,
  TableProps,
  Tag,
  Typography,
} from "antd";
import { useCallback } from "react";
import { useQuery } from "react-query";
import MediaQuery from "react-responsive";
import { Link, useNavigate } from "react-router-dom";
import { Api } from "../../services/Api";
import { getColorForUptime } from "../../utils/getColorForUptime";

function dateToAge(date: string) {
  const now = new Date();
  const parsed = new Date(date);
  const differenceInMinutes = Math.floor(
    (now.getTime() - parsed.getTime()) / 60000
  );
  if (differenceInMinutes < 1) {
    return "agora mesmo";
  }
  if (differenceInMinutes < 60) {
    return `${differenceInMinutes} ${
      differenceInMinutes > 1 ? "minutos" : "minuto"
    }`;
  }
  const differenceInHours = Math.floor(differenceInMinutes / 60);
  if (differenceInHours < 60) {
    return `${differenceInHours} ${differenceInHours > 1 ? "horas" : "hora"}`;
  }
  const differenceInDays = Math.floor(differenceInHours / 24);
  if (differenceInDays < 60) {
    return `${differenceInDays} ${differenceInDays > 1 ? "dias" : "dia"}`;
  }
  const differenceInMonths = Math.floor(differenceInDays / 30);
  if (differenceInMonths < 60) {
    return `${differenceInMonths} ${differenceInMonths > 1 ? "meses" : "mês"}`;
  }
  const differenceInYears = Math.floor(differenceInMonths / 12);
  if (differenceInYears < 60) {
    return `${differenceInYears} ${differenceInYears > 1 ? "anos" : "ano"}`;
  }
}

const { confirm } = Modal;

function ListMonitors() {
  const navigate = useNavigate();

  const { data, isLoading, refetch } = useQuery("monitors", async () => {
    const response = await Api.get("/monitors");
    return response.data.map((item: any) => ({
      ...item,
      key: item.id,
      age: dateToAge(item.createdAt),
    }));
  });

  const deleteMonitoring = useCallback(
    (id: string) => {
      Api.delete(`/monitors/${id}`).finally(() => refetch());
    },
    [refetch]
  );

  const showDeleteConfirm = (id: string) => {
    confirm({
      title: "Deseja excluir o monitoramento?",
      icon: <ExclamationCircleFilled />,
      content:
        "O histórico completo de latência, disponibilidade e incidentes será perdido.",
      okText: "Excluir",
      okType: "danger",
      cancelText: "Manter",
      onOk() {
        deleteMonitoring(id);
      },
    });
  };

  const columns: TableProps<any>["columns"] = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, row) => (
        <Link to={`${row.id}`}>
          <div
            style={{
              width: ".7rem",
              height: ".7rem",
              borderRadius: "1rem",
              marginRight: ".6rem",
              display: "inline-block",
              background: row.up ? "#2ecc71" : "#e74c3c",
            }}
          ></div>
          {text}
        </Link>
      ),
    },
    {
      title: "Criado há",
      dataIndex: "age",
      key: "age",
      render: (text) => (
        <Typography.Text type="secondary">{text}</Typography.Text>
      ),
    },
    {
      title: "Destination",
      dataIndex: "address",
      key: "address",
      render: (text) => (
        <Typography.Text type="secondary">{text}</Typography.Text>
      ),
    },
    {
      title: "Status das últimas 24h",
      key: "last24HoursUptime",
      dataIndex: "last24HoursUptime",
      render: (samples) => (
        <Space size={2}>
          {!!samples &&
            Array.isArray(samples) &&
            samples.map((item: any) => (
              <div
                key={item.startDate}
                style={{
                  width: 8,
                  height: 24,
                  background: getColorForUptime(item.uptimePercentage),
                  borderRadius: 8,
                }}
              ></div>
            ))}
        </Space>
      ),
    },
    {
      title: "",
      key: "action",
      render: (text, row) => {
        const items: MenuProps["items"] = [
          {
            key: "edit",
            icon: <EditOutlined />,
            label: <Link to={`${row.id}/edit`}>Editar</Link>,
          },
          {
            key: "remove",
            danger: true,
            icon: <DeleteOutlined />,
            label: "Excluir",
            onClick: () => showDeleteConfirm(row.key),
          },
        ];

        return (
          <Dropdown menu={{ items }}>
            <a href="/dashboard" onClick={(e) => e.preventDefault()}>
              <Space>
                Ações
                <DownOutlined />
              </Space>
            </a>
          </Dropdown>
        );
      },
    },
  ];

  return (
    <>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Monitoramentos</Breadcrumb.Item>
      </Breadcrumb>
      <div
        className="secondary-card"
        style={{
          background: "#ffffff",
          minHeight: 280,
          borderRadius: 8,
          overflow: "auto",
        }}
      >
        <Flex justify="end" align="center" style={{ marginBottom: "1rem" }}>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              navigate("new");
            }}
          >
            Configurar um novo monitoramento
          </Button>
        </Flex>

        <MediaQuery maxWidth={600}>
          <Skeleton loading={isLoading}>
            {data?.map((m: any) => (
              <Card
                style={{ marginBottom: "1rem" }}
                onClick={() => {
                  navigate(`/monitors/${m.id}`);
                }}
              >
                <Flex
                  justify="space-between"
                  align="center"
                  style={{
                    marginBottom: ".6rem",
                  }}
                >
                  <Typography.Text>{m.name}</Typography.Text>
                  {m.up ? (
                    <Tag color="green">On</Tag>
                  ) : (
                    <Tag color="red">Off</Tag>
                  )}
                </Flex>

                {m.last24HoursUptime && (
                  <Space size={2}>
                    {!!m.last24HoursUptime &&
                      Array.isArray(m.last24HoursUptime) &&
                      m.last24HoursUptime.map((item: any) => (
                        <div
                          key={item.startDate}
                          style={{
                            width: 8,
                            height: 24,
                            background: getColorForUptime(
                              item.uptimePercentage
                            ),
                            borderRadius: 8,
                          }}
                        ></div>
                      ))}
                  </Space>
                )}
              </Card>
            ))}
          </Skeleton>
        </MediaQuery>

        <MediaQuery minWidth={601}>
          <Table
            columns={columns}
            dataSource={data}
            loading={isLoading}
            pagination={false}
          />
        </MediaQuery>
      </div>
    </>
  );
}

export default ListMonitors;
