import { CheckOutlined } from "@ant-design/icons";
import { Breadcrumb, Col, Flex, List, Row, Typography } from "antd";
import { useQuery } from "react-query";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { MonitorForm } from "../../components/MonitorForm";
import { Api } from "../../services/Api";

function EditMonitor() {
  const { id } = useParams();

  const { data: monitor } = useQuery(`monitors:${id}`, async () => {
    const { data } = await Api.get(`/monitors/${id}`);
    return data;
  });

  return (
    <>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>
          <Link to="../">Monitoramentos</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{monitor?.name}</Breadcrumb.Item>
      </Breadcrumb>
      <div
        style={{
          background: "#ffffff",
          minHeight: 280,
          padding: 24,
          borderRadius: 8,
          paddingTop: "3rem",
          paddingBottom: "3rem",
        }}
      >
        <Row>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Typography.Title style={{ margin: 0 }}>
              Monitoramento
            </Typography.Title>
            <Typography.Paragraph
              style={{ margin: 0, marginBottom: "1rem" }}
              type="secondary"
            >
              Monitore a disponibilidade de um link de internet, serviço,
              servidor ou website.
            </Typography.Paragraph>

            <List
              size="small"
              bordered
              style={{
                width: "fit-content",
              }}
              dataSource={[
                "Disponibilidade",
                "Histórico de latência",
                "Registro de incidentes (quedas)",
                "Relatórios de SLA",
              ]}
              renderItem={(item: any) => (
                <List.Item>
                  <Flex gap={"middle"}>
                    <CheckOutlined />
                    <Typography.Text>{item}</Typography.Text>
                  </Flex>
                </List.Item>
              )}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <MonitorForm id={id} />
          </Col>
        </Row>
      </div>
    </>
  );
}

export default EditMonitor;
