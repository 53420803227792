import { Card, Flex, Typography } from "antd";
import Logo from "../../assets/logo.svg";

function RegisterForFormsSuccess() {
  return (
    <>
      <Flex
        align="center"
        justify="center"
        style={{
          background: "#f0f2f5",
          minHeight: "100vh",
        }}
      >
        <Flex
          style={{
            flexDirection: "column",
            gap: 24,
            width: "100%",
            maxWidth: 480,
          }}
          align="start"
        >
          <div
            style={{
              paddingLeft: 24,
              paddingRight: 24,
            }}
          >
            <img src={Logo} alt="" style={{ maxHeight: 42 }} />
          </div>
          <Card title="Criar conta" bordered={false} style={{ width: "100%" }}>
            <Typography.Title level={3} style={{ marginTop: 0 }}>
              Tudo certo!
            </Typography.Title>
            <Typography.Paragraph type="secondary">
              Por favor, clique no link enviado ao seu e-mail para formar a sua
              identidade.
            </Typography.Paragraph>
          </Card>
        </Flex>
      </Flex>
    </>
  );
}

export default RegisterForFormsSuccess;
