import {
  Button,
  Form,
  Input,
  Select,
  SelectProps,
  Skeleton,
  message,
} from "antd";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router";
import { Api } from "../../services/Api";
import { FC, useEffect, useState } from "react";
import { getErrorMessage } from "../../utils/getErrorMessage";

interface Props {
  id?: string;
}

type FieldType = {
  name?: string;
  address?: string;
  contacts: string[];
};

export const MonitorForm: FC<Props> = ({ id }) => {
  const navigate = useNavigate();
  const [isLoadingForm, setIsLoadingForm] = useState<boolean>(true);
  const [initialValues, setInitialValues] = useState({});
  const [messageApi, contextHolder] = message.useMessage();

  const { mutate, isLoading } = useMutation({
    // @ts-ignore
    mutationFn: async (data: any) => {
      if (id) {
        await Api.patch(`/monitors/${id}`, {
          name: data.name,
          address: data.address,
          contacts: data.contacts,
        });
        navigate(`../`, {
          replace: true,
        });
      } else {
        const response = await Api.post(`/monitors`, {
          name: data.name,
          address: data.address,
          contacts: data.contacts,
        });
        navigate(`../${response.data.id}`, {
          replace: true,
        });
      }
    },
    onError: (error) => {
      messageApi.open({
        type: "error",
        content: getErrorMessage(error),
      });
    },
  });

  const { data: contacts, isLoading: isLoadingContacts } = useQuery<
    SelectProps["options"]
  >(
    `contacts`,
    async () => {
      const { data } = await Api.get(`/contacts`);
      return data.map((r: any) => ({
        value: r.id,
        label: r.name,
      }));
    },
    {
      initialData: [],
    }
  );

  useEffect(() => {
    if (!id) return setIsLoadingForm(false);
    Api.get(`/monitors/${id}`).then(({ data }) => {
      setInitialValues(data);
      setIsLoadingForm(false);
    });
  }, [id]);

  if (isLoadingForm) return <Skeleton />;

  return (
    <Form
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      style={{ maxWidth: 600 }}
      initialValues={initialValues}
      onFinish={(values) => {
        mutate(values);
      }}
      autoComplete="off"
    >
      {contextHolder}
      <Form.Item<FieldType>
        label="Nome"
        name="name"
        rules={[
          {
            required: true,
            message: "Informe um nome para o monitoramento.",
          },
        ]}
      >
        <Input autoFocus />
      </Form.Item>

      <Form.Item<FieldType>
        label="IP ou domínio"
        name="address"
        rules={[
          {
            required: true,
            message: "Informe um IP, ou domínio para monitorar",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item<FieldType> label="Contatos" name="contacts">
        <Select
          mode="multiple"
          style={{ width: "100%" }}
          placeholder="para o envio de notificações"
          options={contacts}
          loading={isLoadingContacts}
        />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" htmlType="submit" loading={isLoading}>
          Salvar
        </Button>
      </Form.Item>
    </Form>
  );
};
