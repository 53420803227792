import { Breadcrumb, Col, Flex, Row, Typography } from "antd";
import { Link, useParams } from "react-router-dom";
import { ContactForm } from "../../components/ContactForm";
import { useQuery } from "react-query";
import { Api } from "../../services/Api";

function EditContact() {
  const { id } = useParams();

  const { data } = useQuery(`contacts:${id}`, async () => {
    const { data } = await Api.get(`/contacts/${id}`);
    return data;
  });

  return (
    <>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>
          <Link to="../">Contatos</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{data?.name}</Breadcrumb.Item>
      </Breadcrumb>
      <div
        style={{
          background: "#ffffff",
          padding: 24,
          borderRadius: 8,
          paddingTop: "3rem",
          paddingBottom: "3rem",
        }}
      >
        <Row style={{ height: "100%" }}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Flex align="center" style={{ height: "100%" }}>
              <div>
                <Typography.Title style={{ margin: 0 }}>
                  Contato
                </Typography.Title>
                <Typography.Paragraph
                  style={{ marginBottom: 0 }}
                  type="secondary"
                >
                  Contatos poderão ser atribuídos a monitoramentos para serem
                  notificados sobre quedas, retornos e relatórios.
                </Typography.Paragraph>
              </div>
            </Flex>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <ContactForm id={id} />
          </Col>
        </Row>
      </div>
    </>
  );
}

export default EditContact;
